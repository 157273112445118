import { createRouter, createWebHistory  } from 'vue-router';

import qs from 'qs';
import store from './store';

const LayoutExplorer = () => import('@/layouts/LayoutExplorer');
const LayoutExplorerDemo = () => import('@/layouts/LayoutExplorerDemo');
const ExplorerMap = () => import('@/views/ExplorerMap');
const LayoutExplorerStorymaps = () => import ('@/views/storymaps/Storymaps');
const LayoutSelectorStorymaps = () => import ('@/views/storymaps/LayoutSelectorStorymaps');
const DocumentationFindSpot = () => import('@/views/documentation/DocumentationFindSpot');
const DocumentationPerspectiveComputation = () => import('@/views/documentation/DocumentationPerspectiveComputation');
const DocumentationAlignImprovement = () => import('@/views/documentation/DocumentationAlignImprovement');

const AuthRoutes = [
  // Path need to be setup manually when using route-link for example. Be careful when your changing a path here
  {
    path: 'account-activate',
    components: {
      default: ExplorerMap,
      modal: () => import('@/components/user/UserAuthActivate')
    }
  },
  {
    path: 'password-reset',
    components: {
      default: ExplorerMap,
      modal: () => import('@/components/user/UserAuthPasswordReset')
    }
  },
  {
    path: 'password-forgot',
    components: {
      default: ExplorerMap,
      modal: () => import('@/components/user/UserAuthPasswordForgot')
    },
    props: {
      modal: {
        modalVisible: true
      }
    }
  }
];

const router = createRouter({
  hashbang: false,
  linkActiveClass: 'active',
  history: createWebHistory(__dirname),
  parseQuery: (query) => {
    return qs.parse(query, { comma: true });
  },
  stringifyQuery(query) {
    const result = qs.stringify(query, {
      encode: false,
      arrayFormat: 'comma',
      skipNulls: true,
    });
    return result ?? '';
  },
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('@/views/Index'),
    },
    {
      path: '/:ownerSlug',
      component: () => import('@/views/owner/OwnerSlug'),
    },
    {
      path: '/owner/:ownerSlug',
      component: () => import('@/views/owner/OwnerSlug'),
    },
    {
      path: '/owner/:ownerSlug/visit',
      redirect: (to) => {
        const { params } = to;
        return { path: '/visit', query: { owner_slug: params.ownerSlug } };
      },
    },
    {
      path: '/owner/:ownerSlug/contribute',
      redirect: (to) => {
        const { params } = to;
        return { path: '/contribute', query: { owner_slug: params.ownerSlug } };
      },
    },
    {
      path: '/news',
      name: 'News',
      component: () => import('@/views/News'),
    },
    {
      path: '/process',
      name: 'TechnicalProcess',
      component: () => import('@/views/TechnicalProcess'),
    },
    {
      path: '/documentation',
      name: 'Documentation',
      children: [
        {
          path: 'findspot',
          name: 'DocumentationFindSpot',
          component: DocumentationFindSpot,
        },
        {
          path: 'perspective',
          name: 'DocumentationPerspectiveComputation',
          component: DocumentationPerspectiveComputation
        },
        {
          path: 'align_improvement',
          name: 'DocumentationAlignImprovement',
          component: DocumentationAlignImprovement
        }
      ],
    },
    {
      path: '/development_documentation',
      name: 'DevelopmentDocumentation',
      component: () => import('@/views/DevelopmentDocumentation'),
    },
    {
      path: '/press',
      name: 'Press',
      component: () => import('@/views/Press'),
    },
    {
      path: '/policy',
      name: 'Policy',
      component: () => import('@/views/Policy'),
    },
    {
      path: '/contact',
      name: 'Contact',
      component: () => import('@/views/Contact'),
    },
    {
      path: '/visit/',
      redirect: { name: 'ExplorerMapVisit' },
      component: LayoutExplorer,
      meta: {
        firstChildName: 'ExplorerMapVisit',
      },
      children: [
        {
          path: '',
          name: 'ExplorerMapVisit',
          component: ExplorerMap,
        },
        {
          path: ':imageId(\\d+)',
          name: 'ExplorerVisitId',
          component: () => import('@/views/visit/VisitId'),
        },
        ...AuthRoutes,
      ],
    },
    {
      path: '/visit/:imageId(\\d+)/screenshot',
      component: () => import('@/views/visit/VisitScreenshot'),
    },
    {
      path: '/contribute/',
      redirect: { name: 'ExplorerMapContribute' },
      component: LayoutExplorer,
      meta: {
        firstChildName: 'ExplorerMapContribute',
      },
      children: [
        {
          path: '',
          name: 'ExplorerMapContribute',
          component: ExplorerMap,
        },
        {
          path: ':imageId(\\d+)',
          name: 'ExplorerContributeId',
          component: () => import('@/views/contribute/ContributeId'),
        },
        ...AuthRoutes,
      ],
    },
    {
      path: '/geolocalise',
      component: () => import('@/layouts/LayoutGeolocalise'),
      name: 'Geolocalise',
      redirect: '/contribute',
      children: [
        {
          path: ':imageId(\\d+)',
          redirect: ':imageId(\\d+)/split',
        },
        {
          path: ':imageId(\\d+)/select',
          component: () => import('@/views/geolocalise/GeolocaliseId'),
          name: 'SelectMode',
          beforeEnter(to, from, next) {
            if (to.query.reset === true) {
              store.dispatch('geolocalisation/stopLastGeolocalisation');
            }
            store.commit('geolocalisation/SET_STEP', 0);
            next();
          },
        },
        {
          path: ':imageId(\\d+)/split',
          component: () => import('@/views/geolocalise/GeolocaliseId'),
          name: 'ProvideSplit',
          beforeEnter(to, from, next) {
            store.commit('geolocalisation/SET_STEP', 1);
            next();
          },
        },
        {
          path: ':imageId(\\d+)/location',
          component: () => import('@/views/geolocalise/GeolocaliseId'),
          name: 'ProvideLocation',
          beforeEnter(to, from, next) {
            store.commit('geolocalisation/SET_STEP', 2);
            next();
          },
        },
        {
          path: ':imageId(\\d+)/direction',
          component: () => import('@/views/geolocalise/GeolocaliseId'),
          name: 'ProvideDirection',
          beforeEnter(to, from, next) {
            store.commit('geolocalisation/SET_STEP', 3);
            next();
          },
        },
        {
          path: ':imageId(\\d+)/align',
          component: () => import('@/views/geolocalise/GeolocaliseId'),
          name: 'AlignPoints',
          beforeEnter(to, from, next) {
            store.commit('geolocalisation/SET_STEP', 4);
            next();
          },
        },
        {
          path: ':imageId(\\d+)/perspective',
          component: () => import('@/views/geolocalise/GeolocaliseId'),
          name: 'ImprovePerspective',
          beforeEnter(to, from, next) {
            store.commit('geolocalisation/SET_STEP', 5);
            next();
          },
        },
        {
          path: ':imageId(\\d+)/observation',
          component: () => import('@/views/geolocalise/GeolocaliseId'),
          name: 'ProvideObservation',
          beforeEnter(to, from, next) {
            store.commit('geolocalisation/SET_STEP', 6);
            next();
          },
        },
        {
          path: ':imageId(\\d+)/bravo',
          component: () => import('@/views/geolocalise/GeolocaliseId'),
          name: 'Bravo',
          beforeEnter(to, from, next) {
            store.commit('geolocalisation/SET_STEP', 7);
            next();
          },
        },
      ],
    },
    {
      path: '/validate/:geolocalisationId(\\d+)',
      component: () => import('@/layouts/LayoutValidate'),
      name: 'Validation',
      children: [
        {
          path: '/validate/:geolocalisationId(\\d+)',
          component: () => import('@/views/validate/ValidateId'),
          name: 'ValidateAlignPoints',
          beforeEnter(to, from, next) {
            store.commit('geolocalisation/SET_STEP', 7);
            next();
          },
        },
      ],
    },
    {
      path: '/socialsignin-redirect', // For google Authentication
      component: () => import('@/components/user/UserAuthSocialReceiver'),
      name: 'SocialSignInRedirect',
    },
    {
      path: '/.well-known/change-password',
      redirect: () => '/visit/password-forgot',
    },
    // ***
    //  Compatibility with old owners homepage syntax (especially with /ethz)
    //  ***
    {
      path: '/(ethz|acv|swisstopo|vlb|imaginerio|unil|bcuf|zurich|esslingen)',
      redirect: (to) => '/owner/' + to.path.slice(1),
    },
    {
      path: '/demo/',
      name: 'explorerDemo',
      redirect: { name: 'DemoExplorerMapVisit' },
      component: LayoutExplorerDemo,
      meta: {
        firstChildName: 'ExplorerMapVisit',
      },
      children: [
        {
          path: '',
          name: 'DemoExplorerMapVisit',
          component: ExplorerMap,
          meta: {
            componentInCommon: 'ExplorerMap',
          },
        },
        {
          path: ':imageId',
          name: 'ExplorerDemo',
          component: () => import('@/views/demo/Demo'),
        },
        ...AuthRoutes,
      ],
    },
    {
      path: '/storymaps',
      component: LayoutSelectorStorymaps,
      name: 'SelectStorymaps',
    },
    {
      /*  chapterId is not the real id of the chapter, but its id inside the story
          (it starts from 0 with the intro and is incremented for each next chapter)
      */
      path: '/storymaps/:storyId/:chapterId',
      component: LayoutExplorerStorymaps,
      name: 'ExplorerMapStorymaps',
    },
    {
      path: '/404',
      alias: '/:pathMatch(.*)*',
      component: () => import('@/views/Error404'),
      name: 'Error404',
    },
    {
      path: '/400',
      alias: '/:pathMatch(.*)*',
      component: () => import('@/views/Error400'),
      name: 'Error400',
    },
  ],
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const userState = store.state.user;
  const isLoggedIn = !!store.state.user.token;

  // If user is logged in but infos and roles aren't in store, load them before entering
  if (isLoggedIn && !userState.userInfo?.username) {
    await store.dispatch('user/getInfo');
  }
  if (!isLoggedIn && userState.userInfo.id !== 14) { // Make sure that no user info are shown if token is not anymore valid
    await store.dispatch('user/logout');
  }

  next();
});

async function setModeContribute(routePath) {
  const routeMatchModeContribute = ['/contribute', '/geolocalise', '/validate'];
  let stateModeContribute = false;

  if (routeMatchModeContribute.find(match => routePath.startsWith(match))) {
    stateModeContribute = true;
  }

  if (store.state.app.modeContribute === stateModeContribute) return;

  await store.dispatch('app/setModeContribute', stateModeContribute);
}

router.afterEach(async (to) => {
  await setModeContribute(to.path);

  store.dispatch('app/setCurrentRoute', to.name);
});

export default router;
