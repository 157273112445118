import request from '@/utils/request';

export function getImages(params) {
  return request({
    url: '/images',
    method: 'get',
    params
  });
}

export function getImagesId(params) {
  return request({
    url: '/images/id',
    method: 'get',
    params
  });
}
