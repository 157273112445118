import { getCollections } from '@/api/collections';
import { getOwners } from '@/api/owners';

const state = {
  collections: [],
  owners: []
};

const getters = {
  collectionsByProgressAndDate: (state) => {
    return [...state.collections].sort((a, b) => {
      if (a.progress === b.progress) {
        return new Date(b.date_publi) - new Date(a.date_publi);
      }
      return a.progress - b.progress;
    });
  },

  collectionByName: (state) => {
    return [...state.collections].sort((a, b) => a.name.localeCompare(b.name));
  },

  ownerWithCollections: (state) => {
    if (state.collections.length !== 0) {
      const arr = state.owners.map(owner => {
        let _owner = { ...owner };
        _owner.collections = state.collections.filter(collection => collection.owner.id === owner.id);
        _owner.collections.sort((a, b) => a.name.localeCompare(b.name));
        return _owner;
      });
      return [...arr].sort((a, b) => a.name.localeCompare(b.name));
    } else {
      return state.owners;
    }
  }
};

const mutations = {
  SET_COLLECTIONS: (state, collections) => {
    collections.map(function (collection) {
      collection.progress = Math.floor((collection.nGeoref * 100) / collection.nImages);
      return collection;
    });
    state.collections = collections;
  },

  SET_OWNERS: (state, owners) => {
    state.owners = owners;
  }
};

const actions = {
  loadCollections({ commit }, params) {
    return new Promise((resolve, reject) => {
      getCollections(params)
        .then(response => {
          commit('SET_COLLECTIONS', response.data);
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  loadOwners({ commit }, params) {
    return new Promise((resolve, reject) => {
      getOwners({ ...params })
        .then(response => {
          commit('SET_OWNERS', response.data);
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
