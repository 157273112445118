import request from '@/utils/request';

export function getInfo() {
  return request({
    url: '/me/info',
    method: 'get'
  });
}

export function updateInfo(data) {
  return request({
    url: '/me/info',
    method: 'put',
    data
  });
}

export function updatePassword(data) {
  return request({
    url: '/me/password',
    method: 'put',
    data
  });
}
