import request from '@/utils/request';

export function getAttributes(params) {
    if (params.original_id) {
        return request({
            url: `/owners/${params.owner_id_slug}/original_images/${params.original_id}/attributes/`,
            method: 'get'
        });
    }

    return request({
        url: `/images/${params.id}/attributes/?image_width=500`,
        method: 'get'
    });
}

export function getFootprint(params, cancelToken) {
    return request({
        url: `/images/${params.id}/footprint/`,
        method: 'get',
        cancelToken
    });
}

export function checkLock(params) {
    return request({
        url: `/images/${params.id}/checklock/`,
        method: 'get'
    });
}