import axios from 'axios';
import i18n from '@/plugins/vue-i18n';
import store from '@/store';
import { useToast } from 'vue-toastification';

const toast = useToast();

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_SMAPSHOT_API_URL, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000 // request timeout
});

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    const userLang = store.state.user.userInfo.lang;
    const userToken = store.state.user.token;

    if (userToken) {
      config.headers['Authorization'] = `bearer ${userToken}`;
    }
    if (userLang) {
      config['params'] = config['params'] || {}; // Might not exist
      if (!config.params['lang']) {
        config.params['lang'] = userLang;
      }
    }
    return config;
  },
  error => {
    // do something with request error
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  response => response,
  error => {
    const statusExist = error?.response?.status;
    const status = statusExist ? error.response.status : '500';

    // Cancelled requests: hide msg
    if (error?.__CANCEL__) {
      return Promise.reject(error);
    }
    // Error 404: hide msg (should be handled with the appropriate route)
    if (status == 404) {
      return Promise.reject(error);
    }

    const messageExist = error?.response?.data?.detail;
    let message = i18n.global.tm('generic.serverError');
    let type = 'info';

    // Match 5XX and other errors
    if (!toString(status).match(/^5/) && messageExist) {
      message = error.response.data.detail;
      type = 'error';
    }

    toast(message, {
      type: type  // or "success", "error", "default", "info" and "warning"
    });

    store.commit('app/DISABLE_FULL_LOADING', null, { root: true });

    return Promise.reject(error);
  }
);

export default service;
