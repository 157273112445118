import request from '@/utils/request';

export function getObservations(params) {
  return request({
    url: '/observations',
    method: 'get',
    params
  });
}

export function submitObs(params) {
  return request({
    url: '/observations/',
    method: 'post',
    data: params
  });
}

export function updateObs(params) {
  return request({
    url: `/observations/${params.id}`,
    method: 'put',
    data: params
  });
}

export function deleteObs(params) {
  return request({
    url: `/observations/${params.id}`,
    method: 'delete'
  });
}
