/* eslint-disable sort-imports */

/* Polyfill */
import 'core-js/stable';
import 'regenerator-runtime/runtime';
require('intersection-observer'); // For Safari

import { createApp, defineAsyncComponent } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueDOMPurifyHTML from 'vue-dompurify-html';

/* Plugins */
import i18n from '@/plugins/vue-i18n';

const app = createApp(App);
app.use(store);
app.use(router);
app.use(i18n);

import { createMetaManager } from 'vue-meta';
app.use(createMetaManager());

// preRenderAgent
app.config.globalProperties.$isPreRenderAgent = /Prerender/.test(navigator.userAgent);

import { LoadingPlugin, Component } from 'vue-loading-overlay';
app.use(LoadingPlugin);
app.component('Loading', Component);

// Async Components
app
  .component('LayoutSidePage', defineAsyncComponent(() => import('@/layouts/LayoutSidePage')))
  .component('NavAccount', defineAsyncComponent(() => import('@/components/user/UserNavAccount')))
  .component('MenuLang', defineAsyncComponent(() => import('@/components/common/NavSelectLang')))
  .component('MenuNews', defineAsyncComponent(() => import('@/components/common/NavNewsLink')));

import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';
const floatingVueOptions = {
  // Default position offset along main axis (px)
  distance: 5,
  // Element used to compute position and size boundaries
  boundary: undefined,
  // Themes
  themes: {
    tooltip: {
      // Default tooltip placement relative to target element
      placement: 'auto',
    },
    dropdown: {
      container: '#main',
      boundary: undefined
    },
  },
};
app.use(FloatingVue, floatingVueOptions);

// Splitpanes css
import 'splitpanes/dist/splitpanes.css';

import VueLazyLoad from 'vue3-lazyload';
app.use(VueLazyLoad, {
  loading: require('@/assets/images/loading.png'),
  error: require('@/assets/images/error.png'), // Don't use SVG, bug in safari : https://stackoverflow.com/questions/21536937/svg-resizes-on-hover-in-safari-only
});

import InlineSvg from 'vue-inline-svg';
app.component('InlineSvg', InlineSvg);

import Toast, { POSITION } from 'vue-toastification';
const options = {
  // You can set your default options here
  timeout: 2000,
  closeButton: false,
  icon: false,
  position: POSITION.BOTTOM_RIGHT
};
app.use(Toast, options);

/* Performance */
import VueForceNextTick from 'vue-force-next-tick';
app.use(VueForceNextTick);
app.use(VueDOMPurifyHTML);
/* Accessibility */

import('focus-visible');

//Vue.config.productionTip = process.env.NODE_ENV === 'production';

// Asynchronous navigations with transition
router.isReady().then(() => app.mount('#app'));

export default app;
