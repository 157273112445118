import { submitCorrection } from '@/api/corrections';

const state = {
  list: {}
};

const mutations = {
  SET_CORRECTION: (state, { imageId, type, value }) => {
    state.list[`${imageId}_${type}`] = value;
  },
  DELETE_CORRECTION: (state, { imageId, type }) => {
    delete state.list[`${imageId}_${type}`];
  },
};

const actions = {
  saveCorrection({ commit }, { imageId, type, value }) {
    commit('SET_CORRECTION', { imageId, type, value });
  },

  revertCorrection({ commit }, { imageId, type }) {
    commit('DELETE_CORRECTION', { imageId, type });
  },

  async sendCorrection({ commit, rootState }, { type, imageId, value }) {
    const res = await submitCorrection({
      image_id: imageId,
      new_value: value,
      type
    });

    commit('DELETE_CORRECTION', { imageId, type });

    return res;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
