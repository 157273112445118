import { getNews } from '@/api/news';

const state = {
  lastSeenNews: null,
  news: [],
  totalCount: 0,
};

const getters = {
  newsByYear: (state) => {
    return state.news.reduce((acc, news) => {
      const year = new Date(news.published_at).getFullYear();
      if (!acc[year]) {
        acc[year] = [];
      }
      acc[year].push(news);
      return acc;
    }, {});
  },
};

const mutations = {
  SET_NEWS: (state, news) => {
    state.news = news;
  },
  SET_TOTAL_COUNT: (state, totalCount) => {
    state.totalCount = totalCount;
  },
  SET_LAST_SEEN_NEWS: (state, news) => {
    state.lastSeenNews = news;
  },
  ADD_NEWS: (state, news) => {
    state.news = [...state.news, ...news];
  }
};

const actions = {
  loadNews({ commit }, { params, replace = true }) {
    return new Promise((resolve, reject) => {
      getNews(params)
        .then((response) => {
          if (replace) {
            commit('SET_NEWS', response.data.data.news);
          } else {
            commit('ADD_NEWS', response.data.data.news);
          }
          commit('SET_TOTAL_COUNT', response.data.pagination.total_records);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  setLastSeenNews({ commit }, news) {
    commit('SET_LAST_SEEN_NEWS', news);
  },
  reloadNews({ commit }) {
    actions.loadNews({ commit }, { params: { offset: 0, limit: state.news.length } });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
