<template>
  <div
    id="main"
    class="h-full"
    :class="{ modeContribute: modeContribute }"
  >
    <loading
      :active="fullLoading ? true : undefined"
      :is-full-page="true"
    />
    <!-- Could be improved by teleport on Vue 3 -->
    <CreateAccount
      v-if="modalOpened === 'createAccount'"
      :modal-visible="modalOpened === 'createAccount'"
    /><!-- v-if is required for lazy import to works -->
    <PasswordForgot
      v-if="modalOpened === 'passwordForgot'"
      :modal-visible="modalOpened === 'passwordForgot'"
    /><!-- v-if is required for lazy import to works -->

    <router-view />
    <CookieBanner v-if="hasAcceptedCookiesPolicy === null" />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { mapActions, mapState, useStore } from 'vuex';

const CookieBanner = defineAsyncComponent(() => import('@/components/common/CookieBanner'));
const CreateAccount = defineAsyncComponent(() => import('@/components/user/UserAuthCreate'));
const PasswordForgot = defineAsyncComponent(() => import('@/components/user/UserAuthPasswordForgot'));


export default {
  name: 'App',

  components: {
    CreateAccount,
    PasswordForgot,
    CookieBanner
  },

  setup() {
    let unsubscribeLoadMeta = null;

    return { unsubscribeLoadMeta };
  },

  computed: {
    faviconPath() {
      return this.modeContribute ? '/favicon/contribute' : '/favicon/visit';
    },

    ...mapState('app', ['fullLoading', 'modeContribute', 'modalOpened']),
    ...mapState('user', ['hasAcceptedCookiesPolicy'])
  },

  created() {},

  mounted() {
    this.loadOwners();
    this.loadCollections();

    // Reload metadata if language changed
    const store = useStore();
    this.unsubscribeLoadMeta = store.watch(
      (state) => state.user.userInfo.lang,
      (oldValue, newValue ) => {
        if (oldValue !== newValue) {
          this.loadOwners();
          this.loadCollections();
          this.reloadNews();
        }
      }
    );
  },

  beforeUnmount() {
    if(this.unsubscribeLoadMeta) this.unsubscribeLoadMeta();
  },

  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      // all titles will be injected into this template
      titleTemplate: (titleChunk) => {
        // If undefined or blank then we don't need the hyphen
        return titleChunk ? `${titleChunk} / Smapshot` : 'Smapshot - The participative time machine';
      },

      link: [
        { rel: 'alternate', hreflang: 'en', href: this.alternateLangHref('en') },
        { rel: 'alternate', hreflang: 'de', href: this.alternateLangHref('de') },
        { rel: 'alternate', hreflang: 'fr', href: this.alternateLangHref('fr') },
        { rel: 'alternate', hreflang: 'it', href: this.alternateLangHref('it') },
        { rel: 'alternate', hreflang: 'pt', href: this.alternateLangHref('pt') },
        { rel: 'favicon', href: `${this.faviconPath}/favicon.ico` },
        { rel: 'icon', type: 'image/png', href: `${this.faviconPath}/favicon-196x196.png`, sizes: '196x196' },
        { rel: 'icon', type: 'image/png', href: `${this.faviconPath}/favicon-96x96.png`, sizes: '96x96' },
        { rel: 'icon', type: 'image/png', href: `${this.faviconPath}/favicon-32x32.png`, sizes: '32x32' },
        { rel: 'icon', type: 'image/png', href: `${this.faviconPath}/favicon-16x16.png`, sizes: '16x16' },
        { rel: 'icon', type: 'image/png', href: `${this.faviconPath}/favicon-128x128.png`, sizes: '128x128' }
      ],

      // Base config
      meta: [
        { name: 'google', content: 'notranslate' },
        { name: 'twitter:dnt', content: 'on' },
        { property: 'fb:app_id', content: '1728750624056757' }
      ]
    };
  },

  methods: {
    alternateLangHref(lang){
      return window.location.origin + this.$router.resolve({ query: Object.assign({}, this.$route.query, { lang: lang }) }).href;
    },

    ...mapActions('metadata', ['loadOwners', 'loadCollections']),
    ...mapActions('news', ['reloadNews'])
  }
};
</script>

<style>
@import "assets/styles/index.postcss";
</style>
