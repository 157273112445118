import request from '@/utils/request';

export function getCollections(params) {
  return request({
    url: '/collections',
    method: 'get',
    params
  });
}

export function getChallengeCollection(params) {
  return request({
    url: '/collections?is_challenge=true',
    method: 'get',
    params
  });
}

export function getMainChallengeCollection(params) {
  return request({
    url: '/collections/?is_main_challenge=true',
    method: 'get',
    params
  });
}
