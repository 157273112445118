const state = {

  /**
   * The list of the pictures previously displayed
   */
  previous: [-1],
};

const getters = {

  /**
   * Get the array with the id of the pictures previously displayed
   * @param {*} state
   * @returns the array with the id of the pictures previously displayed
   */
  getPrevious: state => {
    return state.previous;
  }
};

const mutations = {

  /**
   * Add an id to the list
   * @param {*} id the id to add
   */
  ADD_ID_TO_LIST: (state, id) => {
    if (state.previous.length >= 40) {
      state.previous.shift();
    }
    state.previous.push(id);
  },

  /**
   * Reset the list.
   */
  RESET_LIST: (state) => {
    state.previous = [-1];
  }
};

const actions = {

  /**
   * Add the picture's id to the list of the previous displayed ones
   * @param {*} param0
   * @param {*} id the picture's id
   * @returns true if inserted, else false
   */
  addId({ state, commit }, id) {
    if (id && id >= 0 && !state.previous.includes(id)) {

      if(state.previous.length >= 40){
        state.previous.shift();
      }
      commit('ADD_ID_TO_LIST', id);
      return true;
    } else {
      return false;
    }
  },

  /**
   * Reset the list of displayed pictures.
   * @param {*} param0
   */
  resetList({ commit }) {
    commit('RESET_LIST');
  },


};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
