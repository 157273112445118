const state = {
  fullLoading: false,
  modalOpened: null,
  modeContribute: false,
  modeObservation: false,
  previousCurrentRoute: [],
  notifiedOnContributeWithoutLogin: false
};

const getters = {
  comingFromExternal: state => {
    return (state.previousCurrentRoute.length <= 1);
  }
};

const mutations = {
  SET_MODE_CONTRIBUTE: (state, newState) => {
    state.modeContribute = newState;
  },
  SET_MODE_OBSERVATION: (state, newState) => {
    state.modeObservation = newState;
  },
  SAVE_CURRENT_ROUTE: (state, routeName) => {
    const routes = state.previousCurrentRoute;
    // Keep only 2 routes: previous and current
    if (routes.length >= 2 || routeName === routes[0]) {
      routes.shift();
    }
    routes.push(routeName);
    state.previousCurrentRoute = routes;
  },
  ENABLE_FULL_LOADING: (state) => {
    state.fullLoading = true;
  },
  DISABLE_FULL_LOADING: (state) => {
    state.fullLoading = false;
  },
  SHOW_MODAL: (state, modalName) => {
    state.modalOpened = modalName;
  },
  HIDE_MODAL: (state) => {
    state.modalOpened = null;
  },
  SET_NOTIFIED_WITHOUT_LOGIN: (state) => {
    state.notifiedOnContributeWithoutLogin = true;
  },
};

const actions = {
  setModeContribute({ commit, state }, newState) {
    if (state.modeContribute === newState) return;

    commit('SET_MODE_CONTRIBUTE', newState);
  },

  setModeObservation({ commit }, newState) {
    commit('SET_MODE_OBSERVATION', newState);
  },

  setCurrentRoute({ commit }, routeName) {
    commit('SAVE_CURRENT_ROUTE', routeName);
  },

  enableFullLoading({ commit }) {
    commit('ENABLE_FULL_LOADING');
  },

  disableFullLoading({ commit }) {
    commit('DISABLE_FULL_LOADING');
  },

  showModal({ commit }, modalName) {
    commit('SHOW_MODAL', modalName);
  },

  hideModal({ commit }) {
    commit('HIDE_MODAL');
  },

  setNotifiedWithoutLogin({ commit }) {
    commit('SET_NOTIFIED_WITHOUT_LOGIN');
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
