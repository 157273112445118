import { getStory } from '@/api/stories';

const state = {

  /**
   * The list of the pictures previously displayed
   */
  currentStory:
    {},

  /**
   * The current chapter displayed
   */
  currentChapterId: null,

  /**
   * Define if the map is displayed or not
   */
  display2D: true,

};

const getters = {

  /**
   * Get the current story
   * @param {*} state
   * @returns the current story
   */
  getCurrentStory: state => {
    return state.currentStory;
  },

  /**
   * Get the current chapter displayed
   * @param {*} state
   * @returns the current chapter displayed
   */
  getCurrentChapter: state =>{
    return state.currentStory.chapters[state.currentChapterId];
  },

  /**
   * Get the id of the current chapter displayed
   * @param {*} state
   * @returns the id of the current chapter displayed
   */
  getCurrentChapterId: state =>{
    return state.currentChapterId;
  },

  /**
   * Get the previous chapter if possible
   * @param {*} state
   * @returns the id of the previous chapter, else if there are no previous returns null
   */
  getPreviousChapter: (state)=>{
    if(state.currentChapterId > 0 && state.currentChapterId < state.currentStory.chapters.length){
      return state.currentChapterId-1;
    }

    return null;
  },

  /**
   * Get the previous chapter if possible
   * @param {*} state
   * @returns the id of the previous chapter, else if there are no previous returns null
   */
  getNextChapter: (state)=>{
    if(state.currentChapterId >= 0 && state.currentChapterId < state.currentStory.chapters.length - 1){
      return state.currentChapterId+1;
    }

    return null;
  },

  /**
   * Get the positions of all of the story's picture
   * @param {*} state
   * @returns the positions of all of the story's picture
   */
  getChaptersPositions: (state) => {
    let positions = [];

    /**
     * With the following loop, we get each chapter's location.
     * We start a 1 because 0 is the introduction chapter and there
     * must not be any markers for it as it displayes a bounding box displaying
     * all the markers.
     */
    for (let i = 1; i < state.currentStory.chapters.length; i++) {
      positions.push([state.currentStory.chapters[i].longitude, state.currentStory.chapters[i].latitude]);
    }
    return positions;
  },

  /**
   * Get if we in the store if the map is displayed or not
   * @param {*} state
   * @returns true or false
   */
  getDisplay2D: (state) => {
    return state.display2D;
  }

};

const mutations = {

  /**
   * Change the current chapter to the wanted one according an id
   * @param {*} state
   * @param {*} id the id of the new current chapter
   * @param {*} params
   */
    SET_CURRENT_CHAPTER(state, id) {

      if (typeof id === 'string') {
        id = parseInt(id);
      }
      if (id != null && id >= 0 && id < state.currentStory.chapters.length) {
        state.currentChapterId = id;
      }
    },

    SET_CURRENT_STORY(state, story){
      if(story){
        state.currentStory = story;
      }
    },

    SET_DISPLAY_2D(state, value){
      if(value != null && state.display2D != value){
        state.display2D = value;
      }
    }
  };

  const actions = {


    setCurrentStory({ commit }, imageId){

      return new Promise((resolve, reject) => {
        getStory(imageId)
          .then(response => {
            if(response){
              commit('SET_CURRENT_STORY', response.data);
              resolve();
            }else{
              this.$router.push({ name: 'Error404' });
            }

          })
          .catch(error => {
            reject(error);
          });
      });
    },


  setCurrentChapter({ commit, state, dispatch }, id){

    if(id != null){
      commit('SET_CURRENT_CHAPTER', id);
      const chapter = state.currentStory.chapters[state.currentChapterId];
      if (chapter.view_custom) {
        dispatch('viewer/updateCustomView', {
          transparency: chapter.view_custom.transparency ?? 1,
          showBuilding: chapter.view_custom.showBuilding ?? false,
          buildingsSlider: chapter.view_custom.buildingsSlider ?? 0,
          depthSlider: chapter.view_custom.depthSlider ?? -0.6
        }, { root: true });
      } else {
        dispatch('viewer/updateCustomView', {
          transparency: 1,
          showBuilding: false,
          buildingsSlider: 0,
          depthSlider: -0.6
        }, { root: true });
      }
    }
  },

  isChapter({ state }, id) {

    if(typeof id === 'string'){
      id = parseInt(id);
    }

    return (id != null && id >= 0 && id < state.currentStory.chapters);
  },

  /**
   * Change the type of display for the 2D/3D part in the store
   * @param {*} value the new value (true or false)
   */
  changeDisplayType({ commit }, value){

    if(value != null){
      commit('SET_DISPLAY_2D', value);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
