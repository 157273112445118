import request from '@/utils/request';

export function login(data) {
  return request({
    url: '/auth/local/login/',
    method: 'post',
    data
  });
}

export function createUser(data) {
  return request({
    url: '/auth/local/register',
    method: 'post',
    data
  });
}

export function activateUser(data) {
  return request({
    url: '/auth/local/activate',
    method: 'post',
    data
  });
}

export function forgotPassword(data) {
  return request({
    url: '/auth/forgot',
    method: 'post',
    data
  });
}

export function resetPassword(data) {
  return request({
    url: '/auth/reset',
    method: 'post',
    data
  });
}
