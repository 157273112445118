const state = {
  transparency: 1,
  showBuilding: false,
  buildingsSlider: 0,
  depthSlider: -0.6,
};

const getters = {

  imageTransparencyValue: state => {
    return state.transparency;
  },

  showBuilding: state => {
    return state.showBuilding;
  },

  buildingFilterDistance: state => {
    return state.buildingsSlider;
  },

  imageDepthValue: state => {
    return state.depthSlider;
  },

};

const mutations = {

  SET_VIEWER_PARAMS(state , parameters) {
    state.transparency = parameters.transparency;
    state.showBuilding = parameters.showBuilding;
    state.buildingsSlider = parameters.buildingsSlider;
    state.depthSlider = parameters.depthSlider;
  }

};


const actions = {

  updateCustomView({ commit }, parameters){
    commit('SET_VIEWER_PARAMS', parameters);
  }

};


export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
