import Cookies from 'js-cookie';

import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';

const debug = process.env.NODE_ENV !== 'production';

/*
  Import Modules
*/

// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = require.context('./modules', true, /\.js$/);

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
  const value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});

/*
  Choose persisted data

  - Coookies can be delete in the module 'User' after reject cookies policy
*/

const vuexToken = new VuexPersistence({
  key: 'User-Token', // Careful, it needs to be consistant across backoffice and front-end
  restoreState: (key) => ({
    user: {
      token: Cookies.getJSON(key) || sessionStorage.getItem('User-Token') || null
    }
  }),
  saveState: (key, state) => {
    // Use sessions storage if user reject Cookies Policy
    if (!state.user.hasAcceptedCookiesPolicy) {
      if (state.user.token) {
        return sessionStorage.setItem('User-Token', state.user.token);
      } else {
        return sessionStorage.removeItem('User-Token');
      }
    }

    return Cookies.set(key, state.user.token, {
      expires: 14, // 14 days
      secure: process.env.NODE_ENV === 'production',
      sameSite: 'Strict'
    });
  },
  reducer: (state) => ({
    user: {
      token: state.user.token,
      hasAcceptedCookiesPolicy: state.user.hasAcceptedCookiesPolicy
    }
  })
});

const vuexUser = new VuexPersistence({
  key: 'User',
  restoreState: (key) => Cookies.getJSON(key),
  saveState: (key, state) => {
    // Disable Cookies saving if user reject Cookies Policy
    if (!state.user.hasAcceptedCookiesPolicy) return {};

    return Cookies.set(key, state, {
      expires: 14, // 14 days
      secure: process.env.NODE_ENV === 'production',
      sameSite: 'Strict'
    });
  },
  reducer: (state) => ({
    user: {
      userInfo: state.user.userInfo,
      avatar: state.user.avatar,
      hasAcceptedCookiesPolicy: state.user.hasAcceptedCookiesPolicy
    }
  })
});

const vuexViews = new VuexPersistence({
  key: 'Views',
  restoreState: (key) => Cookies.getJSON(key),
  saveState: (key, state) => {
    // Disable Cookies saving if user reject Cookies Policy
    if (!state.user.hasAcceptedCookiesPolicy) return {};

    return Cookies.set(key, state.explorer, {
      expires: 4, // 4 days
      secure: process.env.NODE_ENV === 'production',
      sameSite: 'Strict'
    });
  },
  reducer: (state) => ({
    explorer: {
      views: state.explorer.views
    },
    user: {
      hasAcceptedCookiesPolicy: state.user.hasAcceptedCookiesPolicy
    }
  })
});

const vuexPreviousRoute = new VuexPersistence({
  storage: window.sessionStorage,
  reducer: (state) => ({
    app: {
      previousCurrentRoute: state.app.previousCurrentRoute
    },
  }),
});

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    app: {
      notifiedOnContributeWithoutLogin: state.app.notifiedOnContributeWithoutLogin
    },
    explorer: {
      map: state.explorer.map,
      imagesFilters: {
        bbox: state.explorer.imagesFilters.bbox,
        owner_id: state.explorer.imagesFilters.owner_id,
        collection_id: state.explorer.imagesFilters.collection_id,
        date_shot_max: state.explorer.imagesFilters.date_shot_max,
        date_shot_min: state.explorer.imagesFilters.date_shot_min,
        keyword: state.explorer.imagesFilters.keyword,
        latitude: state.explorer.imagesFilters.latitude,
        longitude: state.explorer.imagesFilters.longitude,
        sortKey: state.explorer.imagesFilters.sortKey
      },
      slider: {
        opened: state.explorer.slider.opened
      }
    },
    geolocalisation: {
      map: state.geolocalisation.map,
      id: state.geolocalisation.id,
      image_id: state.geolocalisation.image_id,
      step: state.geolocalisation.step,
      currentPose: state.geolocalisation.currentPose,
      currentPoseApriori: {
        locationLocked: state.geolocalisation.currentPoseApriori.locationLocked
      },
      panoramaImages: state.geolocalisation.panoramaImages,
      GCPs: state.geolocalisation.GCPs
    },
    observations: {
      currentObs: state.observations.currentObs
    },
    user: {
      hasAcceptedCookiesPolicy: state.user.hasAcceptedCookiesPolicy
    },
    news: {
      lastSeenNews: state.news.lastSeenNews
    },
  }),
});

/*const vuexDemo = new VuexPersistence({
  storage: window.sessionStorage,
  reducer: (state) => ({
    demo: {
      precedents: state.demo.precedents
    },
  }),
});
*/

const store = createStore({
  modules,
  strict: debug,
  plugins: [
    vuexToken.plugin,
    vuexUser.plugin,
    vuexViews.plugin,
    vuexPreviousRoute.plugin,
    vuexLocal.plugin,
  ]
});

export default store;
