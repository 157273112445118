import request from '@/utils/request';

export function getList(params) {
  return request({
    url: '/geolocalisations/',
    method: 'get',
    params
  });
}

export function getGeolocalisation(params) {
  return request({
    url: `/geolocalisations/${params.geoloc_id}`,
    method: 'get'
  });
}

export function startGeolocalisation(params) {
  return request({
    url: '/geolocalisations/',
    method: 'post',
    data: params
  });
}

export function stopGeolocalisation(params) {
  return request({
    url: `/geolocalisations/${params.id}/save`,
    method: 'put',
    data: params
  });
}

export function storeFootprint(geoloc_id, params) {
  return request({
    url: `/geolocalisations/${geoloc_id}/footprint`,
    method: 'post',
    data: params
  });
}

export function rejectGeolocalisation(params, id) {
  params.state = 'rejected';
  return request({
    url: `/geolocalisations/${id}/state/`,
    method: 'put',
    data: params
  });
}

export function validateGeolocalisation(params, id) {
  params.state = 'validated';
  return request({
    url: `/geolocalisations/${id}/state/`,
    method: 'put',
    data: params
  });
}

export function saveTempGltf(image_id, regionByPx, improveFromVisit=false) {
  return request({
    url: `/gltf/${image_id}/save/`,
    method: 'post',
    data: {
      regionByPx: regionByPx,
      improveFromVisit: improveFromVisit
    }
  });
}

export function deleteTempGltf(image_id, regionByPx, improveFromVisit=false) {
  return request({
    url: `/gltf/${image_id}`,
    method: 'delete',
    data: {
      regionByPx: regionByPx,
      improveFromVisit
    }
  });
}
