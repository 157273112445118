import { deleteObs, getObservations, submitObs, updateObs } from '@/api/observations';

const state = {
  loadedObs: [],
  currentObs: null,
  listObsDrawer: {
    opened: false
  },
  obsIsGeneral: false
};

const defaultObservation = {
  observation: null,
  coord_x: null,
  coord_y: null,
  height: null,
  width: null,
  state: 'created'
};

const getters = {
  // Validated state and created (but only for the logged user)
  loadedObsToDisplay: (state, getters, rootState) => {
    return state.loadedObs.filter(obs => (obs.state === 'validated' || (obs.state === 'created' && obs?.volunteer?.id === rootState.user.userInfo.id)));
  },
  editAccess: (state, getters, rootState) => {
    return state.currentObs && !state.currentObs?.id
      ? true
      : state.currentObs?.volunteer?.id === rootState.user.userInfo.id;
  },
  addingObs: (state, getters) => {
    return state.currentObs && !state.currentObs?.id;
  },
  editingObs: (state, getters) => {
    return state.currentObs && state.currentObs?.id && getters['editAccess'];
  },
  viewingObs: (state, getters) => {
    return !state.currentObs && state.currentObs?.id && !getters['editAccess'];
  }
};

const mutations = {
  LOAD_OBS_LIST: (state, list) => {
    state.loadedObs = list;
    if (!state.listObsDrawer.opened && state.loadedObs.length > 0) {//open list of observations at loading if not empty and if not already opened
      state.listObsDrawer.opened = !state.listObsDrawer.opened;
    }
  },
  INIT_NEW_OBS: state => {
    state.currentObs = Object.assign({}, defaultObservation);
  },
  INIT_VIEW_OBS: (state, index) => {
    state.currentObs = { ...state.loadedObs[index] };
  },
  INIT_UPDATE_OBS: (state, index) => {
    state.currentObs = { ...state.loadedObs[index] };
    if (!state.currentObs.coord_x) { // if edited observation has no rectangle set value of obsIsGeneral to true
      state.obsIsGeneral = true;
    }
  },
  SET_DIMENSIONS: (state, rect) => {
    const areaSelected = !state.obsIsGeneral;
    state.currentObs = { //if user checks generalObs after drawing a rectangle, dimension is reinitialised
      id: state.currentObs.id,
      observation: state.currentObs.observation,
      coord_x: areaSelected ? rect.x: null,
      coord_y: areaSelected ? rect.y: null,
      height: areaSelected ? rect.height: null,
      width: areaSelected ? rect.width: null,
      state: state.currentObs.state,
      volunteer: state.currentObs.volunteer
    };
  },
  SET_TEXT: (state, text) => {
    state.currentObs.observation = text;
  },
  RESET_LOADED_OBS: state => {
    state.loadedObs = [];
  },
  RESET_CURRENT_OBS: state => {
    state.currentObs = Object.assign({}, defaultObservation);
  },
  TOGGLE_LIST_OBS: state => {
    state.listObsDrawer.opened = !state.listObsDrawer.opened;
  },

  TOGGLE_OBS_GENERAL: (state, obsIsGeneralValue) => {
    state.obsIsGeneral = obsIsGeneralValue;
  }
};

const actions = {
  async loadObs({ commit, rootState }, { imageId }) {
    const res = await getObservations({
      image_id: imageId,
      state: ['created', 'validated']
    });
    //sort observations so they are loaded in the right order
    let observationsAll = res.data;
    const obsValidated = observationsAll.filter(obs => obs.state ==='validated');
    const obsUser = observationsAll.filter(obs => obs.volunteer.id === rootState.user.userInfo.id);
    let observationsAllSorted = [...obsValidated, ...obsUser, ...observationsAll];
    //loop to suppress duplicated values
    const ids = [];
    const observationsUnique = [];
    for (const obs of observationsAllSorted) {
      if (ids.includes(obs.id)) {
        continue;
      }
      ids.push(obs.id);
      observationsUnique.push(obs);
    }

    commit('LOAD_OBS_LIST', observationsUnique);
    commit('TOGGLE_OBS_GENERAL', false);//after every reloading set checkbox and obsIsGeneral to false
  },

  async reloadObs({ commit, dispatch }, { imageId }) {
    commit('RESET_CURRENT_OBS');
    await dispatch('loadObs', { imageId });
  },

  async submitOne({ commit, state, rootState, dispatch }, { imageId }) {
    commit('app/ENABLE_FULL_LOADING', null, { root: true });

    if (!state.currentObs.id) {
      await submitObs({
        image_id: imageId,
        coord_x: state.currentObs.coord_x,
        coord_y: state.currentObs.coord_y,
        height: state.currentObs.height,
        width: state.currentObs.width,
        observation: state.currentObs.observation
      });
    } else {
      await updateObs({
        id: state.currentObs.id,
        coord_x: state.currentObs.coord_x,
        coord_y: state.currentObs.coord_y,
        height: state.currentObs.height,
        width: state.currentObs.width,
        observation: state.currentObs.observation
      });
    }
    if (!state.listObsDrawer.opened) {//open list of observations after submit if not already opened
      commit('TOGGLE_LIST_OBS');
    }
    await dispatch('loadObs', { imageId });

    commit('RESET_CURRENT_OBS');
    commit('app/DISABLE_FULL_LOADING', null, { root: true });
  },

  viewObs({ commit }, index) {
    commit('INIT_VIEW_OBS', index);
  },

  editObs({ commit }, index) {
    commit('INIT_UPDATE_OBS', index);
  },

  async deleteObs({ commit, state, rootState, dispatch }, { imageId, index }) {
    commit('app/ENABLE_FULL_LOADING', null, { root: true });
    // Decrement metadata counter without a new request to the server
    if (state.loadedObs[index].state === 'validated') {
      commit('explorer/DECREMENT_NOBS', null, { root: true }); // To update Number of observations
    }
    // Check if Observation is currentObs in edit view
    if (state.currentObs.id === state.loadedObs[index].id) {
      commit('RESET_CURRENT_OBS');
    }

    await deleteObs({ id: state.loadedObs[index].id });
    await dispatch('loadObs', { imageId });

    commit('app/DISABLE_FULL_LOADING', null, { root: true });
  },

  createObs({ commit }) {
    commit('INIT_NEW_OBS');
  },

  resetAllObsValues({ commit }) {
    commit('RESET_LOADED_OBS');
    commit('RESET_CURRENT_OBS');
  },

  setText({ commit }, text) {
    commit('SET_TEXT', text);
  },

  setDimensions({ commit }, rect) {
    commit('SET_DIMENSIONS', rect);
  },

  toggleListObs({ commit }) {
    commit('TOGGLE_LIST_OBS');
  },

  toggleObsGeneral({ commit }, checkboxValue) {
    commit('TOGGLE_OBS_GENERAL', checkboxValue);
    if (checkboxValue) {
      commit('SET_DIMENSIONS', null); //reset rectangle when checkbox is set to true.
    }
  }

};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
